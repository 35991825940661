import React from 'react'
import { SidebarLayout } from '@refeb/sidebar-layout'
import { PortfolioImageItem } from '@refeb/portfolio-image-item'
import { H1, P } from '@refeb/typography'

import image from './toolds.webp'
import bg from './eq-bg.jpg'

const EQUIPMENTS_SUBMENU = {
  WELDING: 'Welding',
  INSPECTION_TOOLS: 'Inspection Tools',
  MATERIAL_TEST: 'Material Test',
  RADIATION_PROTECTION: 'Radiation Protection',
  HEAT_TREATMENT: 'Heat Treatment',
  SURFACE_TREATMENT: 'Surface Treatment',
  LABORATORY: 'Laboratory',
  CLEANING: 'Cleaning',
  PAINTING_COATING_TEST: 'Painting And Coating Test',
  INDUSTRIAL_ENDOSCOPY_EQUIPMENTS: 'Industrial Endoscopy Equipments'
}
export default function layout ({ onClick }) {
  return (
    <div style={{ padding: '1em 0' }}>
      <img src={bg} className='eq-bg' />
      <SidebarLayout>
        <div>
          <H1>Equipments</H1>
          <P>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam
          </P>
        </div>
        <div className='products'>
          {Object.keys(EQUIPMENTS_SUBMENU).map((key, index) => {
            const item = EQUIPMENTS_SUBMENU[key]
            return (
              <div key={index} className='equipment-cat-item' onClick={onClick}>
                <PortfolioImageItem
                  id={String(index)}
                  image={image}
                  showTitle
                  title={item}
                  subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                />
              </div>
            )
          })}
        </div>
      </SidebarLayout>
    </div>
  )
}
