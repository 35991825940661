import React from 'react'
import { Footer } from '@refeb/footer'

import logoImage from './logo.png'

const nav = (props) => {
  return (
    <a
      style={{
        textDecoration: 'none'
      }}
      href={props.to}
      target='_blank'
      rel='noopener noreferrer'
    >
      {props.children}
    </a>
  )
}
export default function () {
  return (
    <Footer
      title='MHMET'
      description='Some description'
      logo={
        <img
          style={{
            height: '50px'
          }}
          src={logoImage}
          alt='logo'
        />
      }
      Navigation={nav}
      items={[
        {
          category: 'title',
          links: [
            {
              title: 'item0',
              to: '/'
            },
            {
              title: 'item1',
              to: '/'
            },
            {
              title: 'item2',
              to: '/'
            },
            {
              title: 'item3',
              to: '/'
            },
            {
              title: 'item4',
              to: '/'
            }
          ]
        },
        {
          category: 'title',
          links: [
            {
              title: 'item0',
              to: '/'
            },
            {
              title: 'item1',
              to: '/'
            },
            {
              title: 'item2',
              to: '/'
            },
            {
              title: 'item3',
              to: '/'
            },
            {
              title: 'item4',
              to: '/'
            }
          ]
        },
        {
          category: 'title',
          links: [
            {
              title: 'item0',
              to: '/'
            },
            {
              title: 'item1',
              to: '/'
            },
            {
              title: 'item2',
              to: '/'
            },
            {
              title: 'item3',
              to: '/'
            },
            {
              title: 'item4',
              to: '/'
            }
          ]
        },
        {
          category: 'title',
          links: [
            {
              title: 'item0',
              to: '/'
            },
            {
              title: 'item1',
              to: '/'
            },
            {
              title: 'item2',
              to: '/'
            },
            {
              title: 'item3',
              to: '/'
            },
            {
              title: 'item4',
              to: '/'
            }
          ]
        }
      ]}
      copyRight={<div>&copy; 2021 MHMET </div>}
    />
  )
}
