import React from 'react'
import { SidebarLayout } from '@refeb/sidebar-layout'
import { H1, H2, P } from '@refeb/typography'
import { TeamMembers } from '@refeb/team-members'

import Header from '../../../Components/Header'

import bg from './ser.jpg'
import image from './service.jpg'

const SERVICES_SUBMENU = {
  INSULATION: 'Insulation',
  WELDING: 'Welding',
  NDT_EXAMINATION: 'NDT Examination',
  COATING_PAINTING: 'Coating & Painting',
  MATERIAL_SELECTION: 'Material Selection',
  LABORATORY_SERVICES: 'Laboratory Services',
  WRAPPING: 'Wrapping',
  CORROSION: 'Corrosion',
  MAINTENANCE: 'Maintenance',
  TUBE_CLEANING: 'Tube Cleaning',
  INSPECTION_SERVICES: 'Inspection Services'
}

export default function ({ onClick }) {
  return (
    <div style={{ padding: '1em 0' }}>
      {/* <img src={bg} className='eq-bg' /> */}
      <Header backgroundImage={bg}>
        <H1>Services</H1>
      </Header>
      <SidebarLayout>
        <div>
          <H2>What we do</H2>
          <P>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam
          </P>
        </div>
        <div className='products'>
          {Object.keys(SERVICES_SUBMENU).map((key, index) => {
            const item = SERVICES_SUBMENU[key]
            return (
              <div key={index} className='equipment-cat-item' onClick={onClick}>
                <TeamMembers
                  id={String(index)}
                  image={image}
                  name={item}
                  description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                  socials={[]}
                  position=''
                  card
                  shadow
                />
              </div>
            )
          })}
        </div>
      </SidebarLayout>
    </div>
  )
}
