import React from 'react'
import { H1, H2, H3, P } from '@refeb/typography'
import { SidebarLayout } from '@refeb/sidebar-layout'
import { Section } from '@refeb/section'
import { Slider } from '@refeb/infinite-react-carousel'
import styled from 'styled-components'

import Breadcrumbs from '../../../Components/Breadcrumbs'
import Tags from '../../../Components/Tags'

import bg from './manufacturing.jpg'
import sectionImage from './dc.jpg'
import image from './google.png'

const StyledSlider = styled(Slider)`
  width: 50%;
  .carousel-dots li {
    width: 12px;
    height: 12px;
    margin: 3px;
  }
  .carousel-dots li button:before {
    width: 5px;
    height: 5px;

    content: ' ';
    border: 2px solid ${(props) => props.theme.colors.tertiaryText};
    border-radius: 50%;
    opacity: 1;
  }

  .carousel-dots li.carousel-dots-active button:before {
    border: 2px solid ${(props) => props.theme.colors.tertiaryText};
    background-color: ${(props) => props.theme.colors.tertiaryText};
  }
`
const Logo = styled.img`
  height: 40px;
  object-fit: contain;
  padding: 14px;
`

const logos = Array(5)
  .fill(1)
  .map((item) => ({ image }))
const OutClientsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.darkBackground};
  display: flex;
  justify-content: center;
`
const OutClients = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4em 1em;
  max-width: 1200px;
  align-self: center;
`
const ContactUs = styled.div`
  background-image: url(${bg});
  height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 2em;
  flex-direction: column;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background: ${(props) => props.theme.colors.darkPrimary}d1;
  }
  * {
    color: white;
    z-index: 1;
    user-select: none;
  }
`

export default function Layout () {
  return (
    <div style={{ padding: '1em 0' }}>
      <img src={bg} className='eq-bg' />
      <SidebarLayout>
        <Breadcrumbs>
          <a href='/' style={{ all: 'unset' }}>
            Manufacturing
          </a>
          <p>Design & Conception</p>
        </Breadcrumbs>
        <div
          style={{
            padding: '1em 0'
          }}
        >
          <Tags />
        </div>
        <div>
          <H1>Design & Conception</H1>
          <P>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam
          </P>
        </div>
        <P>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </P>
        <P>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </P>
      </SidebarLayout>
      <Section
        title='Lorem ipsum dolor sit amet'
        description='nulla varius consequat magna, id molestie ipsum volutpat quis. A true story, that never been told!. Fusce id mi diam, non ornare orci. Pellentesque ipsum erat, facilisis ut venenatis eu, sodales vel dolor.'
        button={<span />}
        image={sectionImage}
        className='section'
        dark
      />
      <SidebarLayout>
        <P>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </P>
      </SidebarLayout>
      <OutClientsContainer>
        <OutClients>
          <div style={{ width: '50%' }}>
            <H2>Our Clients</H2>
            <P>
              Aliquam enim enim, pharetra in sodales at, interdum sit amet dui.
              Nullam vulputate euis od urna non pharetra. Phasellus bland matt
              is ipsum, ac laoreet lorem lacinia et. interum sit amet dui.
            </P>
          </div>
          <StyledSlider dots slidesToShow={3} autoplay arrows={false}>
            {logos.map((item) => {
              return <Logo src={item.image} />
            })}
          </StyledSlider>
        </OutClients>
      </OutClientsContainer>
      <Section
        title='Lorem ipsum dolor sit amet'
        description='nulla varius consequat magna, id molestie ipsum volutpat quis. A true story, that never been told!. Fusce id mi diam, non ornare orci. Pellentesque ipsum erat, facilisis ut venenatis eu, sodales vel dolor.'
        button={<span />}
        image={sectionImage}
        className='section'
        darker
        imageRight
      />
      <SidebarLayout>
        <P>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </P>
        <P>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </P>
      </SidebarLayout>
      <ContactUs>
        <H3>Join us</H3>
        <P style={{ maxWidth: '500px' }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </P>
      </ContactUs>
    </div>
  )
}
