import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Menu, MenuItem, Brand } from '@refeb/menu'

import Equipments from './Layouts/Equipments'
import EquipmentsDetails from './Layouts/EquipmentsDetails'
import Industries from './Layouts/Industries'
import IndustriesDetails from './Layouts/IndustriesDetails'
import Services from './Layouts/Services'
import ServicesDetails from './Layouts/ServicesDetails'
import Products from './Layouts/Products'
import ProductsList from './Layouts/ProductsList'
import ProductsDetails from './Layouts/ProductsDetails'
import Manufacturing from './Layouts/Manufacturing'
import ManufacturingDetails from './Layouts/ManufacturingDetails'

import Footer from './Footer'

import './App.css'
import '@refeb/infinite-react-carousel/build/carousel/style.css'

const theme = {
  fonts: {
    primary: 'Poppins, sans-serif',
    secondary: 'Poppins, sans-serif'
  },

  sizes: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }
}
const colorSets = [
  {
    primary: '#a3acc9',
    darkPrimary: '#423d4c',
    lightPrimary: '#6884b6',
    textIconPrimary: '#484848',
    accent: '#d19e73'
  },
  {
    primary: '#27672E',
    darkPrimary: '#19421d',
    lightPrimary: '#7FBE39',
    textIconPrimary: '#484848',
    accent: '#ffc300'
  },
  {
    primary: '#3a3029',
    darkPrimary: '#343436',
    lightPrimary: '#8f7714',
    textIconPrimary: '#484848',
    accent: '#6f2524'
  },
  {
    primary: '#1c588c',
    darkPrimary: '#222f40',
    lightPrimary: '#a7c6da',
    textIconPrimary: '#484848',
    accent: '#594941'
  },
  {
    primary: '#94b6c2',
    darkPrimary: '#4c7eb1',
    lightPrimary: '#a9bac4',
    textIconPrimary: '#484848',
    accent: '#eeeeee'
  }
]

const PAGES = {
  EQUIPMENTS: 'Equipments',
  EQUIPMENT_DETAILS: 'EquipmentsDetails',
  INDUSTRIES: 'Industries',
  INDUSTRIES_DETAILS: 'IndustriesDetails',
  SERVICES_DETAILS: 'ServicesDetails',
  SERVICES: 'Services',
  PRODUCTS: 'Products',
  PRODUCT_LIST: 'ProductsList',
  PRODUCTS_DETAILS: 'ProductsDetails',
  MANUFACTURING: 'Manufacturing',
  MANUFACTURING_DETAILS: 'ManufacturingDetails'
}
const EQUIPMENTS_SUBMENU = {
  WELDING: 'Welding',
  INSPECTION_TOOLS: 'Inspection Tools',
  MATERIAL_TEST: 'Material Test',
  RADIATION_PROTECTION: 'Radiation Protection',
  HEAT_TREATMENT: 'Heat Treatment',
  SURFACE_TREATMENT: 'Surface Treatment',
  LABORATORY: 'Laboratory',
  CLEANING: 'Cleaning',
  PAINTING_COATING_TEST: 'Painting And Coating Test',
  INDUSTRIAL_ENDOSCOPY_EQUIPMENTS: 'Industrial Endoscopy Equipments'
}
const INDUSTRIES_SUBMENU = {
  MINING: 'Mining',
  CONSTRUCTION: 'Construction',
  OIL_GAS: 'Oil & Gas',
  AGRICULTURE: 'Agriculture'
}
const SERVICES_SUBMENU = {
  INSULATION: 'Insulation',
  WELDING: 'Welding',
  NDT_EXAMINATION: 'NDT Examination',
  COATING_PAINTING: 'Coating & Painting',
  MATERIAL_SELECTION: 'Material Selection',
  LABORATORY_SERVICES: 'Laboratory Services',
  WRAPPING: 'Wrapping',
  CORROSION: 'Corrosion',
  MAINTENANCE: 'Maintenance',
  TUBE_CLEANING: 'Tube Cleaning',
  INSPECTION_SERVICES: 'Inspection Services'
}
const PRODUCTS_SUBMENU = {
  ACCESSORY: 'Accessory',
  CONSUMABLE: 'Consumable',
  CONSTRUCTIONAL: 'Constructional',
  MATERIALS: 'Materials'
}
const MANUFACTURING_SUBMENU = {
  DESIGN_CONCEPTION: 'Design & Conception',
  MACHINING: 'Machining',
  FORGING: 'Forging',
  CASTING: 'Casting'
}

function App () {
  const [component, setComponent] = useState(PAGES.EQUIPMENTS)
  const [colorSet, setColorSet] = useState(0)

  function renderContent () {
    switch (component) {
      case PAGES.EQUIPMENTS:
        return (
          <Equipments onClick={() => setComponent(PAGES.EQUIPMENT_DETAILS)} />
        )
      case PAGES.EQUIPMENT_DETAILS:
        return <EquipmentsDetails />
      case PAGES.INDUSTRIES:
        return (
          <Industries onClick={() => setComponent(PAGES.INDUSTRIES_DETAILS)} />
        )
      case PAGES.INDUSTRIES_DETAILS:
        return <IndustriesDetails />
      case PAGES.SERVICES:
        return <Services onClick={() => setComponent(PAGES.SERVICES_DETAILS)} />
      case PAGES.SERVICES_DETAILS:
        return <ServicesDetails />
      case PAGES.PRODUCTS:
        return <Products onClick={() => setComponent(PAGES.PRODUCT_LIST)} />
      case PAGES.PRODUCT_LIST:
        return (
          <ProductsList onClick={() => setComponent(PAGES.PRODUCTS_DETAILS)} />
        )
      case PAGES.PRODUCTS_DETAILS:
        return <ProductsDetails />
      case PAGES.MANUFACTURING:
        return (
          <Manufacturing
            onClick={() => setComponent(PAGES.MANUFACTURING_DETAILS)}
          />
        )
      case PAGES.MANUFACTURING_DETAILS:
        return <ManufacturingDetails />
      default:
        return <Equipments />
    }
  }
  const mainColors = colorSets[colorSet]
  const colors = {
    primaryText: '#1f1f1f',
    secondaryText: '#777777',
    tertiaryText: '#c2c2c2',
    divider: '#4c5667',

    darkBackground: '#f7f9fb',
    darkerBackground: '#eff1f4',
    lightBackground: '#eeeeee',
    lighterBackground: '#ffffff'
  }
  const colorsToUse = Object.assign({}, colors, mainColors)
  return (
    <ThemeProvider theme={Object.assign({}, theme, { colors: colorsToUse })}>
      <Menu maxWidth={1280}>
        <Brand style={{ color: 'black' }}>MHMET</Brand>
        <MenuItem
          title='Equipments'
          onClick={() => setComponent(PAGES.EQUIPMENTS)}
        >
          {Object.keys(EQUIPMENTS_SUBMENU).map((key) => {
            return (
              <MenuItem
                key={key}
                title={EQUIPMENTS_SUBMENU[key]}
                onClick={() => setComponent(PAGES.EQUIPMENT_DETAILS)}
              />
            )
          })}
        </MenuItem>
        <MenuItem
          title='Industries'
          onClick={() => setComponent(PAGES.INDUSTRIES)}
        >
          {Object.keys(INDUSTRIES_SUBMENU).map((key) => {
            return (
              <MenuItem
                key={key}
                title={INDUSTRIES_SUBMENU[key]}
                onClick={() => setComponent(PAGES.INDUSTRIES_DETAILS)}
              />
            )
          })}
        </MenuItem>
        <MenuItem title='Services' onClick={() => setComponent(PAGES.SERVICES)}>
          {Object.keys(SERVICES_SUBMENU).map((key) => {
            return (
              <MenuItem
                key={key}
                title={SERVICES_SUBMENU[key]}
                onClick={() => setComponent(PAGES.SERVICES_DETAILS)}
              />
            )
          })}
        </MenuItem>
        <MenuItem title='Products' onClick={() => setComponent(PAGES.PRODUCTS)}>
          {Object.keys(PRODUCTS_SUBMENU).map((key) => {
            return (
              <MenuItem
                key={key}
                title={PRODUCTS_SUBMENU[key]}
                onClick={() => setComponent(PAGES.PRODUCT_LIST)}
              />
            )
          })}
        </MenuItem>
        <MenuItem
          title='Manufacturing'
          onClick={() => setComponent(PAGES.MANUFACTURING)}
        >
          {Object.keys(MANUFACTURING_SUBMENU).map((key) => {
            return (
              <MenuItem
                key={key}
                title={MANUFACTURING_SUBMENU[key]}
                onClick={() => setComponent(PAGES.MANUFACTURING_DETAILS)}
              />
            )
          })}
        </MenuItem>
        <MenuItem title='Themes' onClick={() => {}}>
          {colorSets.map((set, index) => {
            return (
              <MenuItem
                title={
                  <div style={{ display: 'flex' }}>
                    {Object.keys(set).map((colorName) => {
                      const backgroundColor = set[colorName]
                      return (
                        <span
                          style={{
                            backgroundColor,
                            width: '30px',
                            height: '30px'
                          }}
                        />
                      )
                    })}
                  </div>
                }
                onClick={() => setColorSet(index)}
              />
            )
          })}
        </MenuItem>
      </Menu>
      {renderContent()}
      <Footer />
    </ThemeProvider>
  )
}

export default App
